// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___20uAb {\n  margin: 0 !important;\n  width: auto !important;\n  width: initial !important;\n  max-width: 100%;\n  max-height: 100%;\n  height: auto !important;\n  height: initial !important;\n  zoom: 100;\n}\n.index-module__container___20uAb:only-child {\n  max-width: 100%;\n}\n@media (max-width: 1024px) and (orientation: portrait) {\n  .index-module__container___20uAb {\n    height: auto;\n    width: 80vw;\n  }\n}\n.index-module__wrapper___PSjvW {\n  /*height: 80vh;*/\n  max-height: 80vh;\n  height: 100%;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 40vh;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,sBAAyB;EAAzB,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,uBAA0B;EAA1B,0BAA0B;EAC1B,SAAS;AACX;AACA;EACE,eAAe;AACjB;AAGA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;EACZ,yBAAiB;KAAjB,sBAAiB;MAAjB,qBAAiB;UAAjB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".container {\n  margin: 0 !important;\n  width: initial !important;\n  max-width: 100%;\n  max-height: 100%;\n  height: initial !important;\n  zoom: 100;\n}\n.container:only-child {\n  max-width: 100%;\n}\n\n\n@media (max-width: 1024px) and (orientation: portrait) {\n  .container {\n    height: auto;\n    width: 80vw;\n  }\n}\n\n.wrapper {\n  /*height: 80vh;*/\n  max-height: 80vh;\n  height: 100%;\n  user-select: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 40vh;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___20uAb",
	"wrapper": "index-module__wrapper___PSjvW"
};
module.exports = exports;
