// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1foHJ {\n  width: 100%;\n  height: 1.6em;\n  position: relative;\n}\n\n.index-module__bar___3C-yC {\n  height: 100%;\n  background: #67328d;\n  width: 0%;\n  transition: all 0.2s linear;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,SAAS;EACT,2BAA2B;AAC7B","file":"index.module.css","sourcesContent":[".container {\n  width: 100%;\n  height: 1.6em;\n  position: relative;\n}\n\n.bar {\n  height: 100%;\n  background: #67328d;\n  width: 0%;\n  transition: all 0.2s linear;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1foHJ",
	"bar": "index-module__bar___3C-yC"
};
module.exports = exports;
