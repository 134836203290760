// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n.index-module__container___3XjuJ {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  position: relative;\n}\n\n.index-module__container___3XjuJ > * {\n  flex-shrink: 0;\n}\n\n.index-module__back___1QO9W {\n  position: absolute;\n  top: -1.8em;\n}\n\n.index-module__links___19m7v {\n  display: flex;\n}\n\n.index-module__links___19m7v > *:nth-child(n+2){\n  margin-left: 0.6em;\n}\n\n@media (min-width: 768px){\n  .index-module__back___1QO9W {\n    top: -2.5em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAGA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AACA;EACE,kBAAkB;AACpB;;AACA;EACE;IACE,WAAW;EACb;AACF","file":"index.module.css","sourcesContent":["\n.container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  position: relative;\n}\n\n.container > * {\n  flex-shrink: 0;\n}\n\n\n.back {\n  position: absolute;\n  top: -1.8em;\n}\n\n.links {\n  display: flex;\n}\n.links > *:nth-child(n+2){\n  margin-left: 0.6em;\n}\n@media (min-width: 768px){\n  .back {\n    top: -2.5em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3XjuJ",
	"back": "index-module__back___1QO9W",
	"links": "index-module__links___19m7v"
};
module.exports = exports;
