// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__empty___6tNbp {\n  height: 21em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.index-module__text___37Gu0 {\n  color: #c6c6c5;\n  font-size: 1.2em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".empty {\n  height: 21em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.text {\n  color: #c6c6c5;\n  font-size: 1.2em;\n}\n"]}]);
// Exports
exports.locals = {
	"empty": "index-module__empty___6tNbp",
	"text": "index-module__text___37Gu0"
};
module.exports = exports;
