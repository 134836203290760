// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3tRVq {\n  position: absolute;\n  left: 1em;\n  top: 107%;\n}\n@media (min-width: 1024px){\n  .index-module__container___3tRVq {\n    left: -6em;\n  }\n}\n@media (min-width: 1366px){\n  .index-module__container___3tRVq {\n    left: -13em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;AACX;AACA;EACE;IACE,UAAU;EACZ;AACF;AAEA;EACE;IACE,WAAW;EACb;AACF","file":"index.module.css","sourcesContent":[".container {\n  position: absolute;\n  left: 1em;\n  top: 107%;\n}\n@media (min-width: 1024px){\n  .container {\n    left: -6em;\n  }\n}\n\n@media (min-width: 1366px){\n  .container {\n    left: -13em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3tRVq"
};
module.exports = exports;
