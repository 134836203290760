// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__commentsWrap___3hJGG {\n  max-height: 75vh;\n  margin: 10px 0 10px -2em;\n  display: flex;\n  flex-direction: column;\n  width: calc(100% + 2em);\n}\n.index-module__container___2Jb3B {\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n  min-width: 40vw;\n}\n.index-module__header___2V_Qd {\n  margin: -1em 0 1em -1em;\n  font-size: 22px;\n}\n.index-module__deleteAllWrap___21nzg {\n  margin: 0 0 -2em -2em;\n}\n.index-module__empty___32Ji5 {\n  font-size: 17px;\n  padding-bottom: 10px;\n  border-bottom: .5px solid #dadad9;\n  margin-bottom: 10px;\n  position: relative;\n  padding-right: 1em;\n  color: grey;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,wBAAwB;EACxB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,eAAe;AACjB;AACA;EACE,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,oBAAoB;EACpB,iCAAiC;EACjC,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb","file":"index.module.css","sourcesContent":[".commentsWrap {\n  max-height: 75vh;\n  margin: 10px 0 10px -2em;\n  display: flex;\n  flex-direction: column;\n  width: calc(100% + 2em);\n}\n.container {\n  display: flex;\n  flex-direction: column;\n  align-items: baseline;\n  min-width: 40vw;\n}\n.header {\n  margin: -1em 0 1em -1em;\n  font-size: 22px;\n}\n.deleteAllWrap {\n  margin: 0 0 -2em -2em;\n}\n.empty {\n  font-size: 17px;\n  padding-bottom: 10px;\n  border-bottom: .5px solid #dadad9;\n  margin-bottom: 10px;\n  position: relative;\n  padding-right: 1em;\n  color: grey;\n}"]}]);
// Exports
exports.locals = {
	"commentsWrap": "index-module__commentsWrap___3hJGG",
	"container": "index-module__container___2Jb3B",
	"header": "index-module__header___2V_Qd",
	"deleteAllWrap": "index-module__deleteAllWrap___21nzg",
	"empty": "index-module__empty___32Ji5"
};
module.exports = exports;
