// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___ROq73 {\n}\n.index-module__container___ROq73 > :nth-child(1) {\n  display: flex;\n  align-items: center;\n}\n.index-module__container___ROq73 > :nth-child(1) > input {\n  order: 1;\n}\n.index-module__container___ROq73 > :nth-child(1) > div {\n  order: 2;\n}\n.index-module__container___ROq73 > label > * {\n  margin: 1px 0.2em 0.2em;\n}\n.index-module__disabled___3V3wZ {\n  background-color: #ddd !important;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,QAAQ;AACV;AAAC;EACC,QAAQ;AACV;AACA;EACE,uBAAuB;AACzB;AACA;EACE,iCAAiC;AACnC","file":"index.module.css","sourcesContent":[".container {\n}\n.container > :nth-child(1) {\n  display: flex;\n  align-items: center;\n}\n.container > :nth-child(1) > input {\n  order: 1;\n}.container > :nth-child(1) > div {\n  order: 2;\n}\n.container > label > * {\n  margin: 1px 0.2em 0.2em;\n}\n.disabled {\n  background-color: #ddd !important;\n}"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___ROq73",
	"disabled": "index-module__disabled___3V3wZ"
};
module.exports = exports;
