// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2bgyF {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: #eeefef;\n  border-radius: 0.4em;\n}\n\n.index-module__container___2bgyF > * {\n  text-align: center;\n}\n\n.index-module__error___2qRkU {\n  font-size: 1.4em;\n  margin-bottom: 1em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".container {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: #eeefef;\n  border-radius: 0.4em;\n}\n\n.container > * {\n  text-align: center;\n}\n\n.error {\n  font-size: 1.4em;\n  margin-bottom: 1em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2bgyF",
	"error": "index-module__error___2qRkU"
};
module.exports = exports;
