// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___Y9HrN {\n  padding: 0 1.2em;\n  height: 6em;\n  margin-bottom: 0.6em;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  background: #eeefef;\n  border-radius: 0.4em;\n  text-decoration: none;\n}\n\n.index-module__text___3wRvL {\n  font-size: 2.4em;\n  color: #7b7979;\n}\n\n.index-module__badge___NK99U {\n  padding: 3px 10px;\n  position: absolute;\n  right: 0;\n}\n\n@media (min-width: 768px){\n  .index-module__container___Y9HrN {\n    height: 4.6em;\n  }\n  .index-module__text___3wRvL {\n    font-size: 1.6em;\n  }\n}\n\n@media (min-width: 1024px){\n  .index-module__container___Y9HrN {\n    height: 3.5em;\n    padding: 0 0.8em;\n    margin-bottom: 0.4em;\n  }\n  .index-module__text___3wRvL {\n    font-size: 1.3em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;EACpB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,QAAQ;AACV;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,aAAa;IACb,gBAAgB;IAChB,oBAAoB;EACtB;EACA;IACE,gBAAgB;EAClB;AACF","file":"index.module.css","sourcesContent":[".container {\n  padding: 0 1.2em;\n  height: 6em;\n  margin-bottom: 0.6em;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  background: #eeefef;\n  border-radius: 0.4em;\n  text-decoration: none;\n}\n\n.text {\n  font-size: 2.4em;\n  color: #7b7979;\n}\n\n.badge {\n  padding: 3px 10px;\n  position: absolute;\n  right: 0;\n}\n\n@media (min-width: 768px){\n  .container {\n    height: 4.6em;\n  }\n  .text {\n    font-size: 1.6em;\n  }\n}\n\n@media (min-width: 1024px){\n  .container {\n    height: 3.5em;\n    padding: 0 0.8em;\n    margin-bottom: 0.4em;\n  }\n  .text {\n    font-size: 1.3em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___Y9HrN",
	"text": "index-module__text___3wRvL",
	"badge": "index-module__badge___NK99U"
};
module.exports = exports;
