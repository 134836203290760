// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2zdeZ {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n}\n\n.index-module__polygon___3cFmg {\n  position: relative;\n  font-size: 0.8em;\n  width: 7.5em !important;\n  height: 5.8em !important;\n}\n\n.index-module__text___21-Ia {\n  font-size: 2.2em;\n  color: #7b7979;\n  text-transform: uppercase;\n  margin-left: 0.7em;\n}\n\n@media (min-width: 768px){\n  .index-module__polygon___3cFmg {\n    font-size: 0.6em;\n  }\n  .index-module__text___21-Ia {\n    font-weight: bold;\n    font-size: 1.4em;\n    margin-left: 0.4em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;EACpB;AACF","file":"index.module.css","sourcesContent":[".container {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n}\n\n.polygon {\n  position: relative;\n  font-size: 0.8em;\n  width: 7.5em !important;\n  height: 5.8em !important;\n}\n\n.text {\n  font-size: 2.2em;\n  color: #7b7979;\n  text-transform: uppercase;\n  margin-left: 0.7em;\n}\n\n@media (min-width: 768px){\n  .polygon {\n    font-size: 0.6em;\n  }\n  .text {\n    font-weight: bold;\n    font-size: 1.4em;\n    margin-left: 0.4em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2zdeZ",
	"polygon": "index-module__polygon___3cFmg",
	"text": "index-module__text___21-Ia"
};
module.exports = exports;
