// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3OcXS {\n  margin-top: 0.5em;\n  width: 100%;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  height: 5.5em;\n  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);\n  z-index: 11000;\n  padding: 0.6em 1.8em 0.6em 0.8em;\n  box-sizing: border-box;\n}\n\n.index-module__message___2jok1 {\n  flex-grow: 1;\n  color: #fff;\n}\n\n.index-module__text___2icsw {\n  font-size: 1.4em;\n}\n\n.index-module__statusIcon___3HZac {\n  width: 2.4em;\n  height: 2.4em;\n  fill: #fff;\n  margin-right: 1.4em;\n  margin-left: 0.5em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,kEAAkE;EAClE,cAAc;EACd,gCAAgC;EAChC,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,UAAU;EACV,mBAAmB;EACnB,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".container {\n  margin-top: 0.5em;\n  width: 100%;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  height: 5.5em;\n  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);\n  z-index: 11000;\n  padding: 0.6em 1.8em 0.6em 0.8em;\n  box-sizing: border-box;\n}\n\n.message {\n  flex-grow: 1;\n  color: #fff;\n}\n\n.text {\n  font-size: 1.4em;\n}\n\n.statusIcon {\n  width: 2.4em;\n  height: 2.4em;\n  fill: #fff;\n  margin-right: 1.4em;\n  margin-left: 0.5em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3OcXS",
	"message": "index-module__message___2jok1",
	"text": "index-module__text___2icsw",
	"statusIcon": "index-module__statusIcon___3HZac"
};
module.exports = exports;
