// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___qJgWX {\n  width: 80vw;\n  height: 55vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.index-module__modalHeader___3TNJO {\n  margin-bottom: 1em;\n}\n\n@media (min-width: 768px){\n  .index-module__container___qJgWX {\n    width: 46em;\n    height: 70vh;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;AACF","file":"index.module.css","sourcesContent":[".container {\n  width: 80vw;\n  height: 55vh;\n  display: flex;\n  flex-direction: column;\n}\n\n.modalHeader {\n  margin-bottom: 1em;\n}\n\n@media (min-width: 768px){\n  .container {\n    width: 46em;\n    height: 70vh;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___qJgWX",
	"modalHeader": "index-module__modalHeader___3TNJO"
};
module.exports = exports;
