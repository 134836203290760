// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2xGwK {\n  width: 85vw;\n  text-align: center;\n}\n\n@media (min-width: 768px){\n  .index-module__container___2xGwK {\n    width: 25em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","file":"index.module.css","sourcesContent":[".container {\n  width: 85vw;\n  text-align: center;\n}\n\n@media (min-width: 768px){\n  .container {\n    width: 25em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2xGwK"
};
module.exports = exports;
