// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___38KCs:not(:first-child) {\n  border-top: 1px solid #eeefef;\n}\n\n.index-module__header___2y5aR {\n  padding: 0 0.5em;\n  height: 2.4em;\n  background: #eeefef;\n}\n\n.index-module__number___2ztPV {\n  flex: 0 0 1.5em;\n}\n\n.index-module__text___3pQa0 {\n  flex-grow: 1;\n}\n\n.index-module__highlight___XHK4i,.index-module__selectable___GyPan:hover {\n  background: #e9e4ee;\n  color: #67318d;\n}\n\n.index-module__selectable___GyPan {\n  cursor: pointer;\n}\n\n.index-module__highlight___XHK4i .index-module__icon___1MkSN,.index-module__selectable___GyPan:hover .index-module__icon___1MkSN {\n  fill: #67318d;\n}\n\n.index-module__inputs___oj--0 {\n  padding: 0.6em;\n  font-size: 0.8em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;AACrB;;AAGA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAGA;EACE,cAAc;EACd,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".container:not(:first-child) {\n  border-top: 1px solid #eeefef;\n}\n\n.header {\n  padding: 0 0.5em;\n  height: 2.4em;\n  background: #eeefef;\n}\n\n\n.number {\n  flex: 0 0 1.5em;\n}\n\n.text {\n  flex-grow: 1;\n}\n\n.highlight,.selectable:hover {\n  background: #e9e4ee;\n  color: #67318d;\n}\n\n.selectable {\n  cursor: pointer;\n}\n\n.highlight .icon,.selectable:hover .icon {\n  fill: #67318d;\n}\n\n\n.inputs {\n  padding: 0.6em;\n  font-size: 0.8em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___38KCs",
	"header": "index-module__header___2y5aR",
	"number": "index-module__number___2ztPV",
	"text": "index-module__text___3pQa0",
	"highlight": "index-module__highlight___XHK4i",
	"selectable": "index-module__selectable___GyPan",
	"icon": "index-module__icon___1MkSN",
	"inputs": "index-module__inputs___oj--0"
};
module.exports = exports;
