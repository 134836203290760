// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__buttons___2WupI {\n  justify-content: space-between;\n  margin-top: 3em;\n}\n\n.index-module__label___UtwrS {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #7b7979;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","file":"index.module.css","sourcesContent":[".buttons {\n  justify-content: space-between;\n  margin-top: 3em;\n}\n\n.label {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #7b7979;\n}\n"]}]);
// Exports
exports.locals = {
	"buttons": "index-module__buttons___2WupI",
	"label": "index-module__label___UtwrS"
};
module.exports = exports;
