// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___JpMO8 {\n  margin-bottom: 10em;\n}\n.index-module__message___12Hcc{\n  font-size: 1.9em;\n  font-weight: 600;\n  color: #7b7979;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","file":"index.module.css","sourcesContent":[".container {\n  margin-bottom: 10em;\n}\n.message{\n  font-size: 1.9em;\n  font-weight: 600;\n  color: #7b7979;\n}"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___JpMO8",
	"message": "index-module__message___12Hcc"
};
module.exports = exports;
