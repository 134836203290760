// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1_s00 {\n  font-size: 1.8em;\n}\n\n\n.index-module__buttons___1D_y1 {\n  justify-content: space-between;\n  margin-top: 3em;\n}\n\n\n.index-module__label___-Hxnf {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #7b7979;\n}\n\n\n@media (min-width: 768px){\n  .index-module__container___1_s00 {\n    font-size: 1em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;;AAGA;EACE,8BAA8B;EAC9B,eAAe;AACjB;;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;;AACA;EACE;IACE,cAAc;EAChB;AACF","file":"index.module.css","sourcesContent":[".container {\n  font-size: 1.8em;\n}\n\n\n.buttons {\n  justify-content: space-between;\n  margin-top: 3em;\n}\n\n.label {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #7b7979;\n}\n@media (min-width: 768px){\n  .container {\n    font-size: 1em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1_s00",
	"buttons": "index-module__buttons___1D_y1",
	"label": "index-module__label___-Hxnf"
};
module.exports = exports;
