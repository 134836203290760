// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1K8KZ {\n  height: 80vh;\n  width: auto;\n  max-width: calc(100% - 4vw) !important;\n}\n.index-module__container___1K8KZ:only-child {\n  max-width: 100%;\n}\n@media (max-width: 1024px) and (orientation: portrait) {\n  .index-module__container___1K8KZ {\n    height: auto;\n    width: 80vw;\n    min-height: 40vh;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,sCAAsC;AACxC;AACA;EACE,eAAe;AACjB;AAGA;EACE;IACE,YAAY;IACZ,WAAW;IACX,gBAAgB;EAClB;AACF","file":"index.module.css","sourcesContent":[".container {\n  height: 80vh;\n  width: auto;\n  max-width: calc(100% - 4vw) !important;\n}\n.container:only-child {\n  max-width: 100%;\n}\n\n\n@media (max-width: 1024px) and (orientation: portrait) {\n  .container {\n    height: auto;\n    width: 80vw;\n    min-height: 40vh;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1K8KZ"
};
module.exports = exports;
