// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__buttonRow___1sWoo {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}\n\n.index-module__or___s2CAv {\n  color: #7b7979;\n  font-size: 1.4em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".buttonRow {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n}\n\n.or {\n  color: #7b7979;\n  font-size: 1.4em;\n}\n"]}]);
// Exports
exports.locals = {
	"buttonRow": "index-module__buttonRow___1sWoo",
	"or": "index-module__or___s2CAv"
};
module.exports = exports;
