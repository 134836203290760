// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___YgfH0 {\n  position: absolute;\n  right: -4.2em;\n  top: 80%;\n  box-shadow: 0 10px 20px rgba(0,0,0,0.09), 0 6px 6px rgba(0,0,0,0.12);\n  z-index: 9999;\n}\n\n.index-module__link___1CkOd {\n  padding: 0 0.8em;\n  font-size: 1.4em;\n  color: #c6c6c5;\n  background: #fff;\n  cursor: pointer;\n  height: 2.2em;\n  display: flex;\n  align-items: center;\n}\n\n.index-module__link___1CkOd:hover {\n  background: #e9e4ee;\n}\n\n.index-module__iconWrapper___njb-1 {\n  width: 3.4em;\n  height: 3.4em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  margin-right: -1.1em;\n}\n\n.index-module__burger___1Iwex {\n  width: 1.2em;\n  height: 1.2em;\n}\n\n.index-module__burger___1Iwex > * {\n  stroke: #8c8c8c;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,QAAQ;EACR,oEAAoE;EACpE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAGA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;AACjB","file":"index.module.css","sourcesContent":[".container {\n  position: absolute;\n  right: -4.2em;\n  top: 80%;\n  box-shadow: 0 10px 20px rgba(0,0,0,0.09), 0 6px 6px rgba(0,0,0,0.12);\n  z-index: 9999;\n}\n\n.link {\n  padding: 0 0.8em;\n  font-size: 1.4em;\n  color: #c6c6c5;\n  background: #fff;\n  cursor: pointer;\n  height: 2.2em;\n  display: flex;\n  align-items: center;\n}\n\n.link:hover {\n  background: #e9e4ee;\n}\n\n\n.iconWrapper {\n  width: 3.4em;\n  height: 3.4em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  margin-right: -1.1em;\n}\n\n.burger {\n  width: 1.2em;\n  height: 1.2em;\n}\n\n.burger > * {\n  stroke: #8c8c8c;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___YgfH0",
	"link": "index-module__link___1CkOd",
	"iconWrapper": "index-module__iconWrapper___njb-1",
	"burger": "index-module__burger___1Iwex"
};
module.exports = exports;
