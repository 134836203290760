// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3mneP {\n  height: 18em;\n  flex-grow: 1;\n  position: relative;\n  margin: 1em 0;\n  background: #fff;\n  border-radius: 1em;\n  padding: 0.8em;\n  text-align: left;\n  color: #888;\n}\n\n.index-module__placeholder___2OJ8F {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.2em;\n}\n\n.index-module__device___3X7j8 {\n  padding: 0.4em;\n  font-size: 1.4em;\n}\n\n.index-module__device___3X7j8:hover {\n  background: #e9e4ee;\n  color: #67318d;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,cAAc;EACd,eAAe;AACjB","file":"index.module.css","sourcesContent":[".container {\n  height: 18em;\n  flex-grow: 1;\n  position: relative;\n  margin: 1em 0;\n  background: #fff;\n  border-radius: 1em;\n  padding: 0.8em;\n  text-align: left;\n  color: #888;\n}\n\n.placeholder {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 1.2em;\n}\n\n.device {\n  padding: 0.4em;\n  font-size: 1.4em;\n}\n\n.device:hover {\n  background: #e9e4ee;\n  color: #67318d;\n  cursor: pointer;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3mneP",
	"placeholder": "index-module__placeholder___2OJ8F",
	"device": "index-module__device___3X7j8"
};
module.exports = exports;
