// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__deleteConfirmationModal___2wL9K {\n  width: 85vw;\n}\n\n.index-module__text___3oZ9O {\n  font-size: 2.6em;\n  color: #7b7979;\n  font-weight: 500;\n}\n\n.index-module__buttons___1SACZ {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  margin-top: 3em;\n}\n\n.index-module__noButton___ek1pF {\n  background: #7b7979 !important;\n}\n\n@media (min-width: 768px){\n  .index-module__deleteConfirmationModal___2wL9K {\n    width: 34em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,WAAW;EACb;AACF","file":"index.module.css","sourcesContent":[".deleteConfirmationModal {\n  width: 85vw;\n}\n\n.text {\n  font-size: 2.6em;\n  color: #7b7979;\n  font-weight: 500;\n}\n\n.buttons {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  margin-top: 3em;\n}\n\n.noButton {\n  background: #7b7979 !important;\n}\n\n@media (min-width: 768px){\n  .deleteConfirmationModal {\n    width: 34em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"deleteConfirmationModal": "index-module__deleteConfirmationModal___2wL9K",
	"text": "index-module__text___3oZ9O",
	"buttons": "index-module__buttons___1SACZ",
	"noButton": "index-module__noButton___ek1pF"
};
module.exports = exports;
