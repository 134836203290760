// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__icon___2e1a5 {\n  width: 1.2em;\n  height: 1.2em;\n  transform: rotateY(180deg);\n  color: #8c8c8c;\n}\n.index-module__indicate___37fj6 {\n  position: absolute;\n  right: -2px;\n  top: 5px;\n  color: #a70000;\n  font-size: 14px;\n}\n.index-module__iconWrapper___1WDKt {\n  background: none;\n  cursor: pointer;\n  width: 3.4em;\n  height: 3.4em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,0BAA0B;EAC1B,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,cAAc;EACd,eAAe;AACjB;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".icon {\n  width: 1.2em;\n  height: 1.2em;\n  transform: rotateY(180deg);\n  color: #8c8c8c;\n}\n.indicate {\n  position: absolute;\n  right: -2px;\n  top: 5px;\n  color: #a70000;\n  font-size: 14px;\n}\n\n.iconWrapper {\n  background: none;\n  cursor: pointer;\n  width: 3.4em;\n  height: 3.4em;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n}"]}]);
// Exports
exports.locals = {
	"icon": "index-module__icon___2e1a5",
	"indicate": "index-module__indicate___37fj6",
	"iconWrapper": "index-module__iconWrapper___1WDKt"
};
module.exports = exports;
