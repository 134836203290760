// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___2K6vl {\n  margin-bottom: 1.2em;\n  display: block;\n}\n\n.index-module__hidden___2EDw- {\n  display: none;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","file":"index.module.css","sourcesContent":[".container {\n  margin-bottom: 1.2em;\n  display: block;\n}\n\n.hidden {\n  display: none;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___2K6vl",
	"hidden": "index-module__hidden___2EDw-"
};
module.exports = exports;
