// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___IVvfN {\n  width: 70vw;\n  max-width: calc(100% - 4vw) !important;\n}\n.index-module__container___IVvfN:only-child {\n  max-width: 100%;\n}\n.index-module__audio___EO0Jk {\n  width: 100%;\n}\n.index-module__audio___EO0Jk, .index-module__audio___EO0Jk:hover {\n  outline: none;\n}\n@media (max-width: 1024px) and (orientation: portrait) {\n  .index-module__container___IVvfN {\n    height: auto;\n    width: 90vw;\n    min-height: 40vh;\n  }\n}\n.index-module__icon___3kdCT {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #67328d;\n  width: 100%;\n  min-height: 60vh;\n}\n.index-module__icon___3kdCT > svg {\n  font-size: 3em !important;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sCAAsC;AACxC;AACA;EACE,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;AACf;AAEA;EACE;IACE,YAAY;IACZ,WAAW;IACX,gBAAgB;EAClB;AACF;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B","file":"index.module.css","sourcesContent":[".container {\n  width: 70vw;\n  max-width: calc(100% - 4vw) !important;\n}\n.container:only-child {\n  max-width: 100%;\n}\n.audio {\n  width: 100%;\n}\n.audio, .audio:hover {\n  outline: none;\n}\n\n@media (max-width: 1024px) and (orientation: portrait) {\n  .container {\n    height: auto;\n    width: 90vw;\n    min-height: 40vh;\n  }\n}\n\n.icon {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #67328d;\n  width: 100%;\n  min-height: 60vh;\n}\n.icon > svg {\n  font-size: 3em !important;\n}"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___IVvfN",
	"audio": "index-module__audio___EO0Jk",
	"icon": "index-module__icon___3kdCT"
};
module.exports = exports;
