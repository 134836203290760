// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___Qeih4 {\n  padding: 0 1.6em 0 0.8em;\n  height: 4em;\n  margin-bottom: 0.4em;\n  justify-content: center;\n  background: #eeefef;\n  border-radius: 0.4em;\n  position: relative;\n}\n\n.index-module__container___Qeih4:hover,.index-module__container___Qeih4.index-module__color___3Wlav {\n  background: #e9e4ee;\n}\n\n.index-module__pointer___1xS-d {\n  cursor: pointer;\n}\n\n.index-module__text___3osmL {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #000;\n  flex-grow: 1;\n}\n\n.index-module__container___Qeih4.index-module__color___3Wlav .index-module__text___3osmL {\n  color: #67318d;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,WAAW;EACX,oBAAoB;EACpB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,cAAc;AAChB","file":"index.module.css","sourcesContent":[".container {\n  padding: 0 1.6em 0 0.8em;\n  height: 4em;\n  margin-bottom: 0.4em;\n  justify-content: center;\n  background: #eeefef;\n  border-radius: 0.4em;\n  position: relative;\n}\n\n.container:hover,.container.color {\n  background: #e9e4ee;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.text {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #000;\n  flex-grow: 1;\n}\n\n.container.color .text {\n  color: #67318d;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___Qeih4",
	"color": "index-module__color___3Wlav",
	"pointer": "index-module__pointer___1xS-d",
	"text": "index-module__text___3osmL"
};
module.exports = exports;
