// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1KiAY {\n  background: #eeefef;\n  border-radius: 0.4em;\n  height: 6.2em;\n  padding: 0 1em;\n  margin-bottom: 3em;\n  justify-content: space-between;\n  position: relative;\n}\n\n.index-module__title___CC2uU {\n  font-size: 2.6em;\n  color: #7b7979;\n  font-weight: 500;\n  text-transform: capitalize;\n}\n\n.index-module__right___27m9b {\n  display: flex;\n  align-items: center;\n}\n\n.index-module__right___27m9b > * {\n  margin-left: 1em;\n}\n\n@media (min-width: 768px){\n  .index-module__container___1KiAY {\n    height: 5.2em;\n  }\n  .index-module__title___CC2uU {\n    font-size: 2.2em;\n  }\n}\n\n@media (min-width: 1024px){\n  .index-module__container___1KiAY {\n    height: 4.6em;\n  }\n  .index-module__title___CC2uU {\n    font-size: 1.7em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,8BAA8B;EAC9B,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,gBAAgB;EAClB;AACF;;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,gBAAgB;EAClB;AACF","file":"index.module.css","sourcesContent":[".container {\n  background: #eeefef;\n  border-radius: 0.4em;\n  height: 6.2em;\n  padding: 0 1em;\n  margin-bottom: 3em;\n  justify-content: space-between;\n  position: relative;\n}\n\n.title {\n  font-size: 2.6em;\n  color: #7b7979;\n  font-weight: 500;\n  text-transform: capitalize;\n}\n\n.right {\n  display: flex;\n  align-items: center;\n}\n.right > * {\n  margin-left: 1em;\n}\n\n@media (min-width: 768px){\n  .container {\n    height: 5.2em;\n  }\n  .title {\n    font-size: 2.2em;\n  }\n}\n@media (min-width: 1024px){\n  .container {\n    height: 4.6em;\n  }\n  .title {\n    font-size: 1.7em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1KiAY",
	"title": "index-module__title___CC2uU",
	"right": "index-module__right___27m9b"
};
module.exports = exports;
