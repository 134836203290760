// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__wrapper___2bn0c {\n  background: #f8f8f7;\n  padding-bottom: 1em;\n}\n.index-module__container___1ERN3 {\n  margin: 0.8em 0;\n}\n.index-module__actionInputs___1pKCc{\n  display: flex;\n  justify-content: space-between;\n}\n.index-module__firstInput___1qi5P{\n  width: 60%;\n}\n.index-module__secondInput___2Dmr7{\n  width: 35%;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ","file":"index.module.css","sourcesContent":[".wrapper {\n  background: #f8f8f7;\n  padding-bottom: 1em;\n}\n.container {\n  margin: 0.8em 0;\n}\n.actionInputs{\n  display: flex;\n  justify-content: space-between;\n}\n.firstInput{\n  width: 60%;\n}\n.secondInput{\n  width: 35%;\n}"]}]);
// Exports
exports.locals = {
	"wrapper": "index-module__wrapper___2bn0c",
	"container": "index-module__container___1ERN3",
	"actionInputs": "index-module__actionInputs___1pKCc",
	"firstInput": "index-module__firstInput___1qi5P",
	"secondInput": "index-module__secondInput___2Dmr7"
};
module.exports = exports;
