// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__icon___3YIDR {\n  width: 1.4em;\n  height: 1.4em;\n  margin-right: 1em;\n}\n\n.index-module__icon___3YIDR > * {\n  stroke: #67318d;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","file":"index.module.css","sourcesContent":[".icon {\n  width: 1.4em;\n  height: 1.4em;\n  margin-right: 1em;\n}\n\n.icon > * {\n  stroke: #67318d;\n}\n"]}]);
// Exports
exports.locals = {
	"icon": "index-module__icon___3YIDR"
};
module.exports = exports;
