// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__error___2e1g3 {\n  color: #e23e4d;\n  transition: opacity 150ms ease-in-out;\n  opacity: 0;\n  font-size: 0.8em;\n  font-weight: 500;\n}\n\n.index-module__large___3VWtd {\n  font-size: 1.1em;\n}\n\n.index-module__top___9OqJ1 {\n  position: absolute;\n  top: 5.6em;\n}\n\n.index-module__entering___2alrx {\n  opacity: 1\n}\n\n.index-module__entered___3yMTw  {\n  opacity: 1\n}\n\n.index-module__exiting___37xaZ  {\n  opacity: 0\n}\n\n.index-module__exited___3cHyS  {\n  opacity: 0\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qCAAqC;EACrC,UAAU;EACV,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE;AACF","file":"index.module.css","sourcesContent":[".error {\n  color: #e23e4d;\n  transition: opacity 150ms ease-in-out;\n  opacity: 0;\n  font-size: 0.8em;\n  font-weight: 500;\n}\n\n.large {\n  font-size: 1.1em;\n}\n\n.top {\n  position: absolute;\n  top: 5.6em;\n}\n\n.entering {\n  opacity: 1\n}\n\n.entered  {\n  opacity: 1\n}\n\n.exiting  {\n  opacity: 0\n}\n\n.exited  {\n  opacity: 0\n}\n"]}]);
// Exports
exports.locals = {
	"error": "index-module__error___2e1g3",
	"large": "index-module__large___3VWtd",
	"top": "index-module__top___9OqJ1",
	"entering": "index-module__entering___2alrx",
	"entered": "index-module__entered___3yMTw",
	"exiting": "index-module__exiting___37xaZ",
	"exited": "index-module__exited___3cHyS"
};
module.exports = exports;
