// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__commentBoxWrap___1D6MA {\n  padding-bottom: 10px;\n  border-bottom: .5px solid #dadad9;\n  margin-bottom: 10px;\n  position: relative;\n  padding-right: 1em;\n}\n.index-module__commentBoxWrap___1D6MA:hover .index-module__deleteBtn___2LzE0 {\n  opacity: 1;\n}\n.index-module__headerComment___KEHqW {\n  display: flex;\n  justify-content: space-between;\n  color: grey;\n}\n.index-module__comment___1ZQpK {\n  font-size: 14px;\n}\n.index-module__deleteBtn___2LzE0 {\n  position: absolute;\n  right: -2em;\n  top: 0;\n  cursor: pointer;\n  transition: all .2s;\n  color: grey;\n  opacity: 0;\n}\n.index-module__deleteBtn___2LzE0:hover {\n  color: #a70000\n}\n.index-module__indicate___1hNA_ {\n  position: absolute;\n  right: -2px;\n  top: -5px;\n  color: #a70000;\n  font-size: 14px;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,iCAAiC;EACjC,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,UAAU;AACZ;AACA;EACE;AACF;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,cAAc;EACd,eAAe;AACjB","file":"index.module.css","sourcesContent":[".commentBoxWrap {\n  padding-bottom: 10px;\n  border-bottom: .5px solid #dadad9;\n  margin-bottom: 10px;\n  position: relative;\n  padding-right: 1em;\n}\n.commentBoxWrap:hover .deleteBtn {\n  opacity: 1;\n}\n.headerComment {\n  display: flex;\n  justify-content: space-between;\n  color: grey;\n}\n.comment {\n  font-size: 14px;\n}\n.deleteBtn {\n  position: absolute;\n  right: -2em;\n  top: 0;\n  cursor: pointer;\n  transition: all .2s;\n  color: grey;\n  opacity: 0;\n}\n.deleteBtn:hover {\n  color: #a70000\n}\n.indicate {\n  position: absolute;\n  right: -2px;\n  top: -5px;\n  color: #a70000;\n  font-size: 14px;\n}"]}]);
// Exports
exports.locals = {
	"commentBoxWrap": "index-module__commentBoxWrap___1D6MA",
	"deleteBtn": "index-module__deleteBtn___2LzE0",
	"headerComment": "index-module__headerComment___KEHqW",
	"comment": "index-module__comment___1ZQpK",
	"indicate": "index-module__indicate___1hNA_"
};
module.exports = exports;
