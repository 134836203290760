// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__notification___2gfYA {\n  position: relative;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".notification {\n  position: relative;\n}"]}]);
// Exports
exports.locals = {
	"notification": "index-module__notification___2gfYA"
};
module.exports = exports;
