// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__labelSubText___fOrRp {\n  font-size: 0.9em;\n  margin: 0 0.4em 1em 0.4em;\n  color: #7b7979;\n}\n\n.index-module__container___2tGJf {\n  /*background-color: #fff;*/\n  border-radius: 0.3em;\n  box-sizing: border-box;\n  color: #888;\n  font-size: 1.1em;\n  margin: 1.2em 0.4em;\n  border: 1px solid #eeefef;\n}\n\n.index-module__number___3OtOD {\n  flex: 0 0 1em;\n}\n\n.index-module__text___1CAie {\n  flex-grow: 1;\n}\n\n.index-module__icon___2Eq9C {\n  width: 1em;\n  height: 1em;\n}\n\n.index-module__placeholder___c52LO {\n  padding: 1em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,YAAY;AACd","file":"index.module.css","sourcesContent":[".labelSubText {\n  font-size: 0.9em;\n  margin: 0 0.4em 1em 0.4em;\n  color: #7b7979;\n}\n\n.container {\n  /*background-color: #fff;*/\n  border-radius: 0.3em;\n  box-sizing: border-box;\n  color: #888;\n  font-size: 1.1em;\n  margin: 1.2em 0.4em;\n  border: 1px solid #eeefef;\n}\n\n.number {\n  flex: 0 0 1em;\n}\n\n.text {\n  flex-grow: 1;\n}\n\n.icon {\n  width: 1em;\n  height: 1em;\n}\n\n.placeholder {\n  padding: 1em;\n}\n"]}]);
// Exports
exports.locals = {
	"labelSubText": "index-module__labelSubText___fOrRp",
	"container": "index-module__container___2tGJf",
	"number": "index-module__number___3OtOD",
	"text": "index-module__text___1CAie",
	"icon": "index-module__icon___2Eq9C",
	"placeholder": "index-module__placeholder___c52LO"
};
module.exports = exports;
