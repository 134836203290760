// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___Jr5Mp {\n  padding: 0 1.6em 0 0.8em;\n  height: 3.4em;\n  cursor: pointer;\n  justify-content: center;\n  background: #e9e4ee;\n  border-radius: 0.4em;\n}\n\n.index-module__text___mieOF {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #67318d;\n}\n\n.index-module__light___1nDLe {\n  background: #fff;\n  margin-top: 1.2em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","file":"index.module.css","sourcesContent":[".container {\n  padding: 0 1.6em 0 0.8em;\n  height: 3.4em;\n  cursor: pointer;\n  justify-content: center;\n  background: #e9e4ee;\n  border-radius: 0.4em;\n}\n\n.text {\n  font-size: 1.2em;\n  font-weight: 600;\n  line-height: 1em;\n  color: #67318d;\n}\n\n.light {\n  background: #fff;\n  margin-top: 1.2em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___Jr5Mp",
	"text": "index-module__text___mieOF",
	"light": "index-module__light___1nDLe"
};
module.exports = exports;
