// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___HkvGT{\n  position: relative;\n}\n.index-module__counter___2xa0a {\n  height: 1em;\n  font-size: 1.1em;\n  line-height: 1em;\n  font-weight: 600;\n  color: #7b7979;\n  position: absolute;\n  right: 0;\n  margin: -18px 0.4em 0.4em;\n  display: block;\n}\n.index-module__hidden___1kySI {\n  display: none;\n}\ntextarea {\n  resize: none;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,yBAAyB;EACzB,cAAc;AAChB;AAEA;EACE,aAAa;AACf;AAEA;EACE,YAAY;AACd","file":"index.module.css","sourcesContent":[".container{\n  position: relative;\n}\n.counter {\n  height: 1em;\n  font-size: 1.1em;\n  line-height: 1em;\n  font-weight: 600;\n  color: #7b7979;\n  position: absolute;\n  right: 0;\n  margin: -18px 0.4em 0.4em;\n  display: block;\n}\n\n.hidden {\n  display: none;\n}\n\ntextarea {\n  resize: none;\n}"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___HkvGT",
	"counter": "index-module__counter___2xa0a",
	"hidden": "index-module__hidden___1kySI"
};
module.exports = exports;
