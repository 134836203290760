// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___3w4_b {\n  width: 100%;\n  padding-top: 100%;\n  position: relative;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.index-module__image___kEDm2,.index-module__placeholder___194Wt {\n  position: absolute;\n  top:0;\n  height:100%;\n  width:100%;\n}\n\n.index-module__placeholder___194Wt {\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n}\n\n.index-module__image___kEDm2 {\n  overflow: hidden;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  transition: opacity 50ms ease-in;\n  cursor: pointer;\n}\n\n.index-module__hide___3VeDn {\n  opacity: 0;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,KAAK;EACL,WAAW;EACX,UAAU;AACZ;;AACA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;;AAEjB;;AAGA;EACE,gBAAgB;EAChB,4BAA4B;EAC5B,2BAA2B;EAC3B,wBAAwB;EACxB,gCAAgC;EAChC,eAAe;AACjB;;AAEA;EACE,UAAU;AACZ","file":"index.module.css","sourcesContent":[".container {\n  width: 100%;\n  padding-top: 100%;\n  position: relative;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.image,.placeholder {\n  position: absolute;\n  top:0;\n  height:100%;\n  width:100%;\n}\n.placeholder {\n  color: #fff;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n\n}\n\n\n.image {\n  overflow: hidden;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  transition: opacity 50ms ease-in;\n  cursor: pointer;\n}\n\n.hide {\n  opacity: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___3w4_b",
	"image": "index-module__image___kEDm2",
	"placeholder": "index-module__placeholder___194Wt",
	"hide": "index-module__hide___3VeDn"
};
module.exports = exports;
