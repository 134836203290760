// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___R37em {\n  height: 1em;\n  font-size: 1.1em;\n  line-height: 1em;\n  font-weight: 600;\n  color: #7b7979;\n  margin: 1px 0.4em 0.4em;\n}\n\n.index-module__container___R37em > *:nth-child(2) {\n  margin-left: 0.8em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB","file":"index.module.css","sourcesContent":[".container {\n  height: 1em;\n  font-size: 1.1em;\n  line-height: 1em;\n  font-weight: 600;\n  color: #7b7979;\n  margin: 1px 0.4em 0.4em;\n}\n\n.container > *:nth-child(2) {\n  margin-left: 0.8em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___R37em"
};
module.exports = exports;
