// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___illz9 {\n  color: #7b7979;\n  text-decoration: none;\n  font-size: 1.8em;\n  display: inline-flex;\n  align-items: center;\n}\n\n.index-module__triangle___N737l {\n  transform: rotate(90deg);\n  width: 0.7em;\n  margin-right: 0.2em;\n  position: relative;\n  margin-right: 0.2em;\n}\n\n.index-module__triangle___N737l > polygon {\n  fill: #7b7979;\n}\n\n@media (min-width: 768px){\n  .index-module__container___illz9 {\n    font-size: 1.6em;\n  }\n  .index-module__triangle___N737l {\n    width: 0.7em;\n    top: 0em;\n  }\n}\n\n@media (min-width: 1024px){\n  .index-module__container___illz9 {\n    font-size: 1.3em;\n  }\n  .index-module__triangle___N737l {\n    width: 0.6em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;EACxB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,YAAY;IACZ,QAAQ;EACV;AACF;;AACA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,YAAY;EACd;AACF","file":"index.module.css","sourcesContent":[".container {\n  color: #7b7979;\n  text-decoration: none;\n  font-size: 1.8em;\n  display: inline-flex;\n  align-items: center;\n}\n\n.triangle {\n  transform: rotate(90deg);\n  width: 0.7em;\n  margin-right: 0.2em;\n  position: relative;\n  margin-right: 0.2em;\n}\n\n.triangle > polygon {\n  fill: #7b7979;\n}\n\n@media (min-width: 768px){\n  .container {\n    font-size: 1.6em;\n  }\n  .triangle {\n    width: 0.7em;\n    top: 0em;\n  }\n}\n@media (min-width: 1024px){\n  .container {\n    font-size: 1.3em;\n  }\n  .triangle {\n    width: 0.6em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___illz9",
	"triangle": "index-module__triangle___N737l"
};
module.exports = exports;
