// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___Vu7uk {\n  width: 8.5em;\n  height: 6.8em;\n}\n.index-module__container___Vu7uk > * {\n  position: absolute;\n}\n.index-module__one___1oOCs {\n  right: 0;\n  top: 3.25em;\n}\n.index-module__two___e3z0t {\n  bottom: 0;\n  left: 0;\n}\n.index-module__three___MQlLn {\n  left: 2em;\n  top: 2em;\n}\n.index-module__four___2xGf9 {\n  top: 0;\n  right: 2.2em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;AACA;EACE,QAAQ;EACR,WAAW;AACb;AAEA;EACE,SAAS;EACT,OAAO;AACT;AAEA;EACE,SAAS;EACT,QAAQ;AACV;AAEA;EACE,MAAM;EACN,YAAY;AACd","file":"index.module.css","sourcesContent":[".container {\n  width: 8.5em;\n  height: 6.8em;\n}\n.container > * {\n  position: absolute;\n}\n.one {\n  right: 0;\n  top: 3.25em;\n}\n\n.two {\n  bottom: 0;\n  left: 0;\n}\n\n.three {\n  left: 2em;\n  top: 2em;\n}\n\n.four {\n  top: 0;\n  right: 2.2em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___Vu7uk",
	"one": "index-module__one___1oOCs",
	"two": "index-module__two___e3z0t",
	"three": "index-module__three___MQlLn",
	"four": "index-module__four___2xGf9"
};
module.exports = exports;
