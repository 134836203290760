// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__device___1d01Q {\n  font-size: 0.7em;\n  min-height: .7em;\n  font-weight: 400;\n  margin-top: 0.2em;\n}\n\n.index-module__purple___2vBh9 {\n  color: #67328d;\n}\n\n.index-module__rightDiv___3uIyB {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","file":"index.module.css","sourcesContent":[".device {\n  font-size: 0.7em;\n  min-height: .7em;\n  font-weight: 400;\n  margin-top: 0.2em;\n}\n\n.purple {\n  color: #67328d;\n}\n.rightDiv {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}"]}]);
// Exports
exports.locals = {
	"device": "index-module__device___1d01Q",
	"purple": "index-module__purple___2vBh9",
	"rightDiv": "index-module__rightDiv___3uIyB"
};
module.exports = exports;
