// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__checkboxes___2bTZa {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  margin-top: 1em;\n  min-height: 5em;\n}\n\n.index-module__checkboxes___2bTZa > label {\n  display: flex;\n  align-items: center;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  width: 50%;\n  margin-bottom: 0.2em;\n}\n\n.index-module__checkboxes___2bTZa > label > div {\n  font-weight: 400;\n}\n\n.index-module__loader___E3YDx {\n  font-size: 0.6em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,yBAAyB;EACzB,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","file":"index.module.css","sourcesContent":[".checkboxes {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n  margin-top: 1em;\n  min-height: 5em;\n}\n\n.checkboxes > label {\n  display: flex;\n  align-items: center;\n  flex-direction: row-reverse;\n  justify-content: flex-end;\n  width: 50%;\n  margin-bottom: 0.2em;\n}\n\n.checkboxes > label > div {\n  font-weight: 400;\n}\n\n.loader {\n  font-size: 0.6em;\n}\n"]}]);
// Exports
exports.locals = {
	"checkboxes": "index-module__checkboxes___2bTZa",
	"loader": "index-module__loader___E3YDx"
};
module.exports = exports;
