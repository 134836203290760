// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1Chvr {\n  display: flex;\n  justify-content: space-between;\n\n}\n\n.index-module__container___1Chvr > * {\n  display: flex;\n  align-items: center;\n  text-align: right;\n}\n\n.index-module__container___1Chvr > label > * {\n  margin: 1px 0.2em 0.2em;\n}\n\n.index-module__checkbox___157aT{\n  display: flex;\n  align-items: center;\n}\n\n.index-module__input___1OarT{\n  max-width: 5rem;\n}\n\n.index-module__disabled___pJqzd {\n  background-color: #ddd !important;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;;AAEhC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AACA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;AACnC","file":"index.module.css","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n\n}\n\n.container > * {\n  display: flex;\n  align-items: center;\n  text-align: right;\n}\n\n.container > label > * {\n  margin: 1px 0.2em 0.2em;\n}\n\n.checkbox{\n  display: flex;\n  align-items: center;\n}\n.input{\n  max-width: 5rem;\n}\n\n.disabled {\n  background-color: #ddd !important;\n}"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1Chvr",
	"checkbox": "index-module__checkbox___157aT",
	"input": "index-module__input___1OarT",
	"disabled": "index-module__disabled___pJqzd"
};
module.exports = exports;
