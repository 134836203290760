// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__badge___1uW4E {\n  position: absolute;\n  top: -10px;\n  right: -10px;\n  padding: 3px 10px;\n  border-radius: 50%;\n  background: red;\n  color: white;\n  font-weight: bold;\n}\n.index-module__disabled___221PO{\n  background: #a489b7 !important;\n  cursor: not-allowed !important;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC","file":"index.module.css","sourcesContent":[".badge {\n  position: absolute;\n  top: -10px;\n  right: -10px;\n  padding: 3px 10px;\n  border-radius: 50%;\n  background: red;\n  color: white;\n  font-weight: bold;\n}\n.disabled{\n  background: #a489b7 !important;\n  cursor: not-allowed !important;\n}\n"]}]);
// Exports
exports.locals = {
	"badge": "index-module__badge___1uW4E",
	"disabled": "index-module__disabled___221PO"
};
module.exports = exports;
