// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__withoutPadding___fe7OY {\n  padding: 0;\n}\n.index-module__withoutPadding___fe7OY > div {\n  padding: 0;\n}\n.index-module__icon___y69D4{\n  margin-top: -10px;\n  font-size: 14px;\n  color: #008000;\n}", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,cAAc;AAChB","file":"index.module.css","sourcesContent":[".withoutPadding {\n  padding: 0;\n}\n.withoutPadding > div {\n  padding: 0;\n}\n.icon{\n  margin-top: -10px;\n  font-size: 14px;\n  color: #008000;\n}"]}]);
// Exports
exports.locals = {
	"withoutPadding": "index-module__withoutPadding___fe7OY",
	"icon": "index-module__icon___y69D4"
};
module.exports = exports;
