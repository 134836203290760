// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___1V5OE {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.index-module__text___1NvR3 {\n  font-size: 1.2em;\n  margin-bottom: 0.5em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,oBAAoB;AACtB","file":"index.module.css","sourcesContent":[".container {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.text {\n  font-size: 1.2em;\n  margin-bottom: 0.5em;\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___1V5OE",
	"text": "index-module__text___1NvR3"
};
module.exports = exports;
