// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__container___McQ5U {\n  margin-bottom: 1em;\n  color: #ac91bd;\n  font-size: 1.9em;\n  font-weight: 600;\n}\n/*.header{*/\n/*float: left;*/\n/*}*/\n.index-module__headerCount___3Kej_{\n  float: right;\n}\n@media (min-width: 768px){\n  .index-module__container___McQ5U {\n    font-size: 1.6em;\n  }\n}\n@media (min-width: 1024px){\n  .index-module__container___McQ5U {\n    font-size: 1.4em;\n  }\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;AACA,WAAW;AACT,eAAe;AACjB,IAAI;AACJ;EACE,YAAY;AACd;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;AACA;EACE;IACE,gBAAgB;EAClB;AACF","file":"index.module.css","sourcesContent":[".container {\n  margin-bottom: 1em;\n  color: #ac91bd;\n  font-size: 1.9em;\n  font-weight: 600;\n}\n/*.header{*/\n  /*float: left;*/\n/*}*/\n.headerCount{\n  float: right;\n}\n\n@media (min-width: 768px){\n  .container {\n    font-size: 1.6em;\n  }\n}\n@media (min-width: 1024px){\n  .container {\n    font-size: 1.4em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"container": "index-module__container___McQ5U",
	"headerCount": "index-module__headerCount___3Kej_"
};
module.exports = exports;
