// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".index-module__loader___RGaZ8 {\n  width: 2.4em;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","file":"index.module.css","sourcesContent":[".loader {\n  width: 2.4em;\n}\n"]}]);
// Exports
exports.locals = {
	"loader": "index-module__loader___RGaZ8"
};
module.exports = exports;
