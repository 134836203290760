// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "\n.index-module__link___1cgBw {\n  text-decoration: none;\n}\n", "",{"version":3,"sources":["index.module.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;AACvB","file":"index.module.css","sourcesContent":["\n.link {\n  text-decoration: none;\n}\n"]}]);
// Exports
exports.locals = {
	"link": "index-module__link___1cgBw"
};
module.exports = exports;
